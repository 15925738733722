<template>
  <form class="phone container" @submit.prevent="addNumber" ref="phones">
    <div class="form-row my-2 justify-content-between">
      <h5 class="col">Phones</h5>
      <add-button
        type="submit"
        v-tooltip="'Press Alt+A while in this form to add a phone number'"
      />
    </div>
    <div class="phones-main">
      <div v-for="(entry, i) in allPhones" :key="i" class="form-row align-items-center my-1">
        <select-input
          label="Type"
          class="col"
          :items="types"
          v-model="entry.phoneTypeId"
          :tabindex="tabProp || 0"
        />
        <phone-input
          :required="true"
          label="Number"
          id="number"
          name="number"
          :validator="v && v.$each[i].phoneNumber"
          :validatorMsgMap="v && validatorMsgMap"
          class="col"
          maxLength="16"
          v-model="entry.phoneNumber"
          data-private="redact"
          :tabProp="tabProp || 0"
        />
        <text-input
          name="ext"
          label="Ext"
          class="col-2"
          v-model="entry.extension"
          :tabindex="tabProp || 0"
        />
        <div class="d-flex align-self-end">
          <button v-if="!noPrimary" type="button" class="btn" @click="setPrimary(i)">
            <template v-if="entry.isPrimary">
              <icon class="text-primary" icon="parking" />
            </template>
            <template v-else>
              <span class="secondary-icon">S</span>
            </template>
          </button>
          <icon-button
            :disabled="allPhones.length === 1"
            @click="deleteNumber(i, entry)"
            type="button"
            icon="trash-alt"
            class="btn text-danger"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { validatorMsgMapBase } from "@/modules/helpers.js";
import shortcutKey from "@/mixins/shortcutKeys.js";
import AddButton from "../common/AddButton.vue";
import SelectInput from "../common/SelectInput.vue";
import PhoneInput from "../common/PhoneInput.vue";
import TextInput from "../common/TextInput.vue";
import Icon from "../common/Icon.vue";
import IconButton from "../common/IconButton.vue";
import { mapState } from "vuex";
export default {
  name: "Phones",
  components: {
    AddButton,
    SelectInput,
    TextInput,
    PhoneInput,
    Icon,
    IconButton
  },
  mixins: [shortcutKey({ keyCode: 65, modifier: "alt" }, "addNumber")],
  props: {
    value: {
      required: true
    },
    v: {
      type: Object
    },
    noPrimary: {
      default() {
        return false;
      }
    },
    items: {
      required: false
    },
    phoneTypes: {
      default: () => "case"
    },
    tabProp: {
      type: Number
    }
  },
  created() {
    this.$store.dispatch("dropdowns/getLocationPhoneTypes");
    this.$store.dispatch("dropdowns/getCasePhoneTypes");
  },
  computed: {
    ...mapState({
      casePhoneTypes: state => state.dropdowns.casePhoneTypes,
      locationPhoneTypes: state => state.dropdowns.locationPhoneTypes
    }),
    validatorMsgMap() {
      return validatorMsgMapBase;
    },

    allPhones() {
      return this.value?.filter(e => !e.isDeleted) || [];
    },
    types() {
      if (!/accession/i.test(this.$route?.fullPath)) {
        return this.locationPhoneTypes;
      }
      return this.casePhoneTypes;
    }
  },
  methods: {
    addNumber() {
      if (!this.value?.length) {
        const numbers = [
          {
            phoneTypeId: null,
            phoneNumber: "",
            ext: "",
            isPrimary: true
          }
        ];
        this.$emit("input", numbers);
      } else {
        this.$emit("input", [
          ...this.value,
          {
            phoneTypeId: null,
            phoneNumer: "",
            ext: "",
            isPrimary: false
          }
        ]);
      }
    },
    deleteNumber(i, entry) {
      const phone = this.value.find(e => e.id != undefined && e.id === entry.id);
      if (phone) {
        const data = this.value.map(e => {
          if (e.id === entry.id) {
            e.isDeleted = true;
          }
          return e;
        });
        return this.$emit("input", data);
      }
      const data = [...this.value.slice(0, i), ...this.value.slice(i + 1)];
      return this.$emit("input", data);
    },
    setPrimary(index) {
      const data = this.value.map((entry, i) => {
        if (i === index) {
          entry.isPrimary = true;
        } else {
          entry.isPrimary = false;
        }
        return entry;
      });
      this.$emit("input", data);
    }
  }
};
</script>

<style lang="scss" scoped>
.actions {
  padding: 0.15rem;
  font-size: 0.8rem;
}
</style>
