<template>
  <div class="admin-edit">
    <div class="d-flex mb-3 justify-content-between align-items-start">
      <h3 class="col">
        <b>{{ userId ? "Edit " : "Add New " }}User</b>
        <loader v-if="isLoading" size="small" class="mx-auto"></loader>
      </h3>
    </div>
    <div class="mb-3">
      <form @submit.prevent="handleSave" v-shortkey="saveShortkey" @shortkey="handleSave">
        <div class="d-flex">
          <TextInput
            class="form-group col-sm-4"
            type="text"
            label="First Name"
            name="firstName"
            maxLength="41"
            v-model="user.firstName"
            :validator="$v.user.firstName"
          />
          <TextInput
            class="form-group col-sm-4"
            type="text"
            label="Middle Name"
            name="middleName"
            maxLength="41"
            v-model="user.middleName"
            :validator="$v.user.middleName"
          />
          <TextInput
            class="form-group col-sm-4"
            type="text"
            label="Last Name"
            name="lastName"
            maxLength="41"
            v-model="user.lastName"
            :validator="$v.user.lastName"
          />
        </div>
        <div class="d-flex">
          <TextInput
            class="form-group col"
            type="email"
            label="Email"
            name="email"
            :disabled="user.id"
            v-model="user.email"
            :validator="$v.user.email"
          />

          <SelectInput
            label="Path Report Previews"
            class="form-group col"
            name="pathReportPreviewLocationId"
            :items="pathOptions"
            :validator="$v.user.pathReportPreviewLocationId"
            v-model="user.pathReportPreviewLocationId"
          />

          <PhoneInput
            class="col"
            name="phoneNumber"
            label="Phone Number"
            v-model="user.phoneNumber"
            :validator="$v.user.phoneNumber"
          />
        </div>
        <div class="d-flex" v-if="user.userTypeId > 20">
          <SelectInput
            label="Task Notification Method"
            class="form-group col"
            name="taskNotificationMethodId"
            :items="taskNotificationMethodOptions"
            v-model="user.taskNotificationMethodId"
          />
          <div class="col">
            <SelectInput
              v-if="user.taskNotificationMethodId"
              label="Notification Priority"
              class="form-group"
              name="taskNotificationPriorityId"
              :items="taskPriorityOptions"
              v-model="user.taskNotificationPriorityId"
            />
          </div>
          <div class="col">
            <SelectInput
              v-if="user.taskNotificationMethodId"
              label="Notify When"
              class="form-group"
              name="taskNotificationEventId"
              :items="taskNotificationTimeOptions"
              v-model="user.taskNotificationEventId"
            />
          </div>
        </div>
        <div class="d-flex">
          <TextInput
            class="form-group col-4"
            type="email"
            label="Alternate Email"
            name="alternateEmail"
            v-model="user.alternateEmail"
            :validator="$v.user.alternateEmail"
          />
        </div>
        <div class="d-flex">
          <select-input
            class="col"
            label="User Type"
            name="userTypeId"
            :items="userTypeOptions"
            :disabled="!!user.id"
            v-model="user.userTypeId"
            :validator="$v.user.userTypeId"
          />
          <roles
            :userTypeFilter="user.userTypeId"
            class="col"
            name="roles"
            ref="roles"
            :key="isLoading"
            v-model="userRoles"
            :validator="$v.user.roles"
          />

          <contact
            :noPrimary="true"
            label="Provider/Location"
            class="form-group col"
            name="providers"
            placeholder="Select Providers"
            :validator="$v.user.providers"
            v-model="user.providers"
            v-if="isClientUser"
          />
          <div class="col" v-else />
        </div>
        <div class="d-flex align-items-start">
          <SelectInput
            label="Client Message Recipient"
            class="col"
            name="isTaskAssignee "
            :items="booleanOptions"
            v-model="user.isTaskAssignee"
            :validator="$v.user.isTaskAssignee"
          />
          <SelectInput
            label="Login Verification Code"
            class="col"
            name="loginVerificationCode"
            :show-clear-button="false"
            :items="mfaCodeOptions"
            v-model="mfaCodeBy"
          />

          <div class="col" />
        </div>
        <div v-if="cytologyModuleEnabled" class="d-flex flex-wrap">
          <h3 class="col-12">Cytology</h3>
          <NumberInput
            label="Slide Limit"
            class="col"
            name="cytSlideLimit"
            v-model="user.cytSlideLimit"
          />
          <NumberInput
            label="Quality Control Percent"
            class="col"
            name="qualityControlPercent"
            v-model="user.qualityControlPercent"
          />
          <div class="col-3" />
        </div>
        <div v-if="isDoctor" class="container border-top mt-2">
          <div class="d-flex my-2">
            <h5>Doctor Information</h5>
          </div>
          <div class="d-flex max-auto">
            <div class="d-flex flex-column w-50">
              <text-input
                class="form-group"
                type="text"
                label="Degrees"
                name="degrees"
                v-model="user.degrees"
                :validator="$v.user.degrees"
              />
              <text-input
                class="form-group hide_arrows"
                type="number"
                name="npi"
                label="NPI"
                maxLength="11"
                v-model="user.npi"
                :validator="$v.user.npi"
              />

              <tag-input
                label="Doctor Proxies"
                placeholder="Select users"
                class="form-group"
                name="doctorProxies"
                valueExpr="userId"
                v-model="user.doctorProxies"
                :dataSource="pathologists"
              />

              <SelectInput
                label="Distribution Error Emails"
                name="distributionEmailErrors "
                :items="booleanOptions"
                v-model="user.distributionErrorEmails"
              />
              <SelectInput
                v-if="availableLabLocations.length > 1"
                class="mb-2"
                label="Default Lab Location"
                name="labLocationId "
                :items="availableLabLocations"
                v-model="user.labLocationId"
              />
            </div>
            <div class="d-flex flex-column">
              <TextInput
                class="form-group col"
                type="text"
                name="address1"
                label="Address"
                v-model="user.address1"
                maxLength="101"
                :validator="$v.user.address1"
              />
              <TextInput
                class="form-group col"
                type="text"
                label="Line 2"
                name="address2"
                v-model="user.address2"
                :validator="$v.user.address2"
                maxLength="101"
              />

              <address-lookup class="col" v-model="user" />
            </div>
          </div>

          <div class="row">
            <Phones
              ref="phoneNumbers"
              class="col"
              :items="phoneTypes"
              v-model="user.phoneNumbers"
            />
            <Emails class="col mx-2" ref="emails" v-model="user.emails" />
          </div>
        </div>
        <div v-if="!userId">
          <SelectInput
            class="col-4"
            label="Default User Settings"
            v-model="selectedDefaultSettings"
            :items="rolesWithDefaultSettings"
            valueExpr="displayName"
            :disabled="rolesWithDefaultSettings.length < 2"
          />
        </div>
        <div
          v-if="labSettings.SuspendWelcomeEmailsToNewUsers && !userId"
          class="d-flex justify-content-start p-2 text-danger"
        >
          This user will not automatically receive a welcome email because lab setting "Suspend
          Welcome Emails To New Users" is enabled.
        </div>
        <div class="d-flex justify-content-between p-2">
          <button
            v-if="isSuperUser && user.settings"
            type="button"
            class="btn btn-primary"
            @click="toggleViewSettings"
          >
            Settings
          </button>
          <div v-else />
          <button
            :disabled="$v.user.$invalid || !permissions.UserCreateEdit"
            type="submit"
            class="btn btn-primary"
          >
            Save
          </button>
        </div>
      </form>
    </div>
    <Modal :status="isSettingsPopupOpen" @close="toggleViewSettings" maxWidth="50vw">
      <h2>User Settings for {{ user.firstName }} {{ user.lastName }} ({{ user.email }})</h2>
      <UserSettingsFields :value="userSettings" ref="userSettings" />
      <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-primary m-2" @click="toggleViewSettings">Close</button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import UserService from "../services/users";
import DropdownService from "../services/dropdown";
import ProviderAPI from "@/services/providers";
import Phones from "@/components/forms/Phones.vue";
import PhoneInput from "@/components/common/PhoneInput.vue";
import Emails from "@/components/forms/Emails.vue";
import { isEmpty } from "lodash";
import { required, maxLength, helpers } from "vuelidate/lib/validators";
import { Contact } from "../components/forms/Selectors";
import Roles from "../components/forms/Selectors/Roles.vue";
import { userTypes } from "../modules/enums";
import {
  altKey,
  booleanLookup,
  createLogComment,
  createLogItem,
  isSuperUser
} from "../modules/helpers";
import auditLog from "../services/AuditLog";
import { cloneDeep } from "lodash";
import AddressLookup from "@/components/common/AddressLookup.vue";
import TextInput from "@/components/common/TextInput.vue";
import Loader from "@/components/common/Loader.vue";
import { DropdownApi } from "@/services";
import SelectInput from "@/components/common/SelectInput.vue";
import TagInput from "@/components/common/TagInput.vue";
import { sortBy } from "lodash";
import NumberInput from "@/components/common/NumberInput.vue";
import { handleErrors } from "@/modules/handleErrors";
import Modal from "@/components/common/Modal.vue";
import UserSettingsFields from "@/components/UserSettingsFields.vue";

const emailRegex = /^(\w[\w._%-]*@\w[\w.-]*\.[a-zA-Z]{2,})$/i;

export default {
  props: {
    userId: {
      type: String
    }
  },

  metaInfo: {
    title: "Users",
    titleTemplate: "IntelliPath - %s"
  },
  components: {
    Phones,
    Emails,
    PhoneInput,
    Contact,
    Roles,
    SelectInput,
    AddressLookup,
    NumberInput,
    TextInput,
    TagInput,
    Loader,
    Modal,
    UserSettingsFields
  },
  data() {
    return {
      pathologists: DropdownApi.searchPathologists,
      user: {
        userName: "",
        userTypeId: null,
        firstName: "",
        lastName: "",
        middleName: null,
        email: "",
        alternateEmail: "",
        phoneNumber: "",
        degrees: "",
        npi: "",
        address1: "",
        address2: "",
        city: "",
        state: null,
        zip: "",
        resultsDiagnosisOnFocusBehaviorId: null,
        pathReportPreviewLocationId: 1,
        isTaskAssignee: false,
        roles: [],
        phoneNumbers: [],
        emails: [],
        providers: [],
        doctorProxies: [],
        sendMfaCodeByEmail: true,
        sendMfaCodeBySms: false,
        labLocationId: null,
        taskNotificationMethodId: 0,
        taskNotificationPriorityId: null,
        taskNotificationEventId: 0
      },
      booleanOptions: booleanLookup.dataSource,
      userTypes,
      contactSearch: ProviderAPI.searchContacts,
      userStore: UserService.searchStore,
      availableLabs: [],
      resultsOptions: [],
      mfaCodeOptions: [
        { displayName: "Email", id: 0 },
        { displayName: "Text", id: 1 },
        { displayName: "Both", id: 2 }
      ],
      MfaEnum: {
        Email: 0,
        Text: 1,
        Both: 2
      },
      isLoading: false,
      isDoctor: false,
      originalUser: {},
      saveShortkey: altKey("s"),
      rolesWithDefaultSettings: [],
      selectedDefaultSettings: null,
      taskNotificationMethodOptions: [
        { id: 0, displayName: "None" },
        { id: 1, displayName: "Email" },
        { id: 2, displayName: "Text" },
        { id: 3, displayName: "Both" }
      ],
      taskPriorityOptions: [],
      taskNotificationTimeOptions: [
        { id: 0, displayName: "Never" },
        { id: 1, displayName: "When Assigned" },
        { id: 2, displayName: "When Due" },
        { id: 3, displayName: "Both" }
      ],
      isSettingsPopupOpen: false,
      isSuperUser: isSuperUser(),
    };
  },
  computed: {
    ...mapState(["currentUser", "currentLab", "labSettings"]),
    ...mapState({
      phoneTypes: state => state.dropdowns.casePhoneTypes,
      pathOptions: state => state.dropdowns.pathReportPreviewLocations,
      availableLabLocations: state => state.availableLabLocations,
      cytologyModuleEnabled: state => state.labSettings.CytologyModuleEnabled
    }),
    ...mapGetters(["permissions"]),
    userTypeOptions() {
      const { userTypeId } = this.currentUser;
      return sortBy(
        userTypes.filter(type => type.id <= userTypeId),
        "displayName"
      );
    },
    userRoles: {
      get() {
        return this.user.roles.map(e => e.id);
      },
      set(value) {
        this.user.roles = value.map(e => ({ id: e }));
        return value;
      }
    },
    isClientUser() {
      return this.user.userTypeId === 20;
    },
    mfaCodeBy: {
      get() {
        if (this.user.sendMfaCodeByEmail && this.user.sendMfaCodeBySms) {
          return this.MfaEnum.Both;
        } else if (this.user.sendMfaCodeBySms) {
          return this.MfaEnum.Text;
        } else {
          return this.MfaEnum.Email;
        }
      },
      set(value) {
        if (value === this.MfaEnum.Both) {
          this.user.sendMfaCodeByEmail = true;
          this.user.sendMfaCodeBySms = true;
        } else if (value === this.MfaEnum.Text) {
          this.user.sendMfaCodeByEmail = false;
          this.user.sendMfaCodeBySms = true;
        } else {
          this.user.sendMfaCodeByEmail = true;
          this.user.sendMfaCodeBySms = false;
        }
      }
    },
    userSettings() {
      if (this.user.settings) {
        return JSON.parse(this.user.settings);
      }
      return null;
    }
  },
  validations() {
    return {
      user: {
        firstName: { required, maxLength: maxLength(40) },
        middleName: { maxLength: maxLength(40) },
        lastName: {
          required,
          maxLength: maxLength(40)
        },
        pathReportPreviewLocationId: {
          required
        },
        userTypeId: {
          required
        },
        isTaskAssignee: {
          required
        },
        roles: {
          required
        },
        providers: {
          required: value => (this.isClientUser ? helpers.req(value) : true)
        },
        sendMfaCodeByEmail: {
          required
        },
        sendMfaCodeBySms: {
          required
        },
        email: {
          required,
          email: value => (value ? emailRegex.test(value) : true)
        },
        phoneNumber: {
          maxLength: 15,
          required: value => (this.user.sendMfaCodeBySms ? helpers.req(value) : true)
        },
        degrees: {
          required: value => {
            if (this.isDoctor) {
              return helpers.req(value);
            }
            return true;
          }
        },
        npi: {
          maxLength: maxLength(10)
        },
        address1: {
          maxLength: maxLength(100)
        },
        address2: {
          maxLength: maxLength(100)
        },
        city: {
          maxLength: maxLength(100)
        },
        zipCode: {
          maxLength: maxLength(11)
        },
        alternateEmail: {
          email: value => (value ? emailRegex.test(value) : true)
        }
      }
    };
  },
  created() {
    // DropdownService.getAvailableLabs().then(res => {
    //   this.availableLabs = res || [];
    // });
    this.$store.dispatch("dropdowns/getCasePhoneTypes");
    this.$store.dispatch("dropdowns/getPathReportPreviewLocations");
    DropdownApi.getTaskPriorities().then(res => {
      this.taskPriorityOptions = [{ id: null, displayName: "All" }, ...res];
    });

    if (this.userId) {
      this.isLoading = true;
      UserService.getUserById(this.userId).then(user => {
        this.originalUser = cloneDeep(user);
        this.user = {
          ...user,
          zipCode: user.zip,
          providers: (user.providers || []).map(e => e.contactId)
        };
        if (!this.user.sendMfaCodeByEmail && !this.user.sendMfaCodeBySms) {
          this.user.sendMfaCodeByEmail = true;
        }
        this.isLoading = false;
      });
    }
  },
  watch: {
    "user.roles": {
      immediate: true,
      deep: true,
      handler(nv) {
        this.rolesWithDefaultSettings = [];
        if (nv?.length) {
          const roleQuery = nv.reduce((acc, curr) => {
            if (acc.length) {
              return [acc, "or", ["id", curr.id]];
            }
            return ["id", curr.id];
          }, []);
          DropdownApi.searchRoles.load({ filter: roleQuery }).then(response => {
            const anyDoctor = response.find(e => e.doctor || e.isDoctor);
            if (anyDoctor) {
              this.isDoctor = true;
            }
            for (const role of response) {
              if (role?.defaultUserSettings) {
                this.rolesWithDefaultSettings.push({
                  id: role.id,
                  displayName: role.displayName,
                  defaultUserSettings: role.defaultUserSettings
                });
              }
              if (!this.selectedDefaultSettings) {
                this.selectedDefaultSettings = this.rolesWithDefaultSettings[0].displayName;
              }
            }
          });
        } else {
          this.isDoctor = false;
        }
      }
    }
  },
  methods: {
    displayUserName(user) {
      if (user.firstName && user.lastName) {
        return `${user.lastName}, ${user.firstName}`;
      }
      return user.username;
    },
    fetchContacts(searchValue) {
      let options = {
        sortFields: [
          {
            field: "Provider.LastName",
            ascending: true
          }
        ],
        searchPaths: ["Provider.FirstName", "Provider.LastName", "ProviderAddress.OfficeName"],
        searchPathsInclusive: true,
        searchValue
      };
      return ProviderAPI.searchContacts(options);
    },
    fetchRoles(searchValue) {
      let options = {
        sortFields: [
          {
            field: "Role.Name",
            ascending: true
          }
        ],
        searchPaths: ["Role.Name"],
        searchPathsInclusive: true,
        searchValue
      };
      return DropdownService.searchRoles(options);
    },
    async handleSave() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        await window.notify("Please verify your input.", "warning");
        return;
      }
      try {
        if (this.userId) {
          const response = await UserService.saveUser({
            ...this.user,
            contacts: this.user.providers,
            zip: this.user.zipCode,
            labId: this.currentLab,
            roles: this.user.roles.map(e => e.id)
          });
          if (response.validationErrors?.length) {
            await window.alert(
              `<ul>${response.validationErrors.map(e => `<li>${e}</li>`).join(",")}</ul>`
            );
            return;
          }
          const logItem = createLogItem({}, 5);
          logItem.comments = createLogComment(this.originalUser, this.user);
          auditLog.insertLogMessage(logItem);
        } else {
          let settings = null;
          if (this.rolesWithDefaultSettings.length && this.selectedDefaultSettings) {
            const roleWithDefaultSettingsToAdd = this.rolesWithDefaultSettings.find(
              e => e.displayName === this.selectedDefaultSettings
            );
            if (roleWithDefaultSettingsToAdd?.id) {
              settings = roleWithDefaultSettingsToAdd.defaultUserSettings;
            }
          }
          const response = await UserService.createUser({
            ...this.user,
            userName: this.user.email,
            labId: this.currentLab,
            zip: this.user.zipCode,
            settings: settings,
            contacts: this.user.providers,
            roles: this.user.roles.map(e => e.id),
            // This doesn't seem to do anything but the backend currently requires it
            resultingInitialFieldId: 1
          });
          if (response.validationErrors?.length) {
            window.alert(response.validationErrors.map(error => `${error} \n`));
            return;
          }
          const logItem = createLogItem({}, 5);
          logItem.comments = `Created users ${this.user.email}`;
          auditLog.insertLogMessage(logItem);
        }
        this.$emit("close");
      } catch (error) {
        handleErrors(error);
      }
    },
    handleErrors(err) {
      if (err.response?.data) {
        const error = err.response.data;
        if (typeof error?.message === "string") {
          alert(error.message);
        } else {
          console.log(error);
        }
      }
    },
    async handleCancel() {
      const { ...user } = this.user;
      if (isEmpty(user)) {
        return this.$emit("close");
      }
      if (
        await window.confirm(`You may have unsaved changes.\n Are you sure you want to continue?`)
      ) {
        this.$emit("close");
      }
    },
    toggleViewSettings() {
      this.isSettingsPopupOpen = !this.isSettingsPopupOpen;
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-btn {
  transition: all 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.nav-link {
  text-transform: capitalize;
  font-weight: 500;
}
.row {
  align-items: center;
}
.form-row {
  align-items: flex-start;
}
.admin-edit {
  max-width: 1024px;
}
</style>
