<template>
  <div>
    <label><b>Roles</b></label>
    <DxTagBox
      v-model="roles"
      placeholder="Select roles..."
      :show-selection-controls="true"
      :search-expr="searchExpr"
      :display-expr="calculateDisplay"
      value-expr="id"
      :data-source="dataSource"
      :maxDisplayTags="5"
      :showDropDownButton="true"
      :search-enabled="true"
      :hideSelectedItems="true"
      :dropdownOptions="dropdownOptions"
    >
    </DxTagBox>
    <div v-if="(validator && validator.$error) || (validator && validator.$invalid)">
      <div
        class="validation-error"
        v-for="(key, index) in Object.keys(validator.$params)"
        :key="index"
      >
        <span class="error" v-if="!validator[key]">
          {{ validatorMsgMap[key] }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import DropDownApis from "../../../services/dropdown.js";
import DxTagBox from "devextreme-vue/tag-box";
import { mapState } from "vuex";
import { validatorMsgMapBase } from "../../../modules/helpers.js";
import DataSource from "devextreme/data/data_source";

export default {
  name: "Roles",
  inheritAttrs: false,
  components: {
    DxTagBox
  },
  props: {
    noPrimary: {
      type: Boolean,
      default: false
    },
    setPrimary: {
      type: Function
    },
    value: {
      required: true
    },
    validator: {
      type: Object
    },
    userTypeFilter: {
      type: Number,
      required: false
    }
  },
  created() {
    if (this.userTypeFilter !== undefined) {
      DropDownApis.searchRoles
        .load({ filter: ["userTypeId", "=", this.userTypeFilter] })
        .then(roles => {
          this.dataSource = new DataSource({
            store: roles,
            key: "id",
            select: ["id", "displayName", "isDoctor", "userTypeId"],
            sort: ["displayName"]
          });
        });
    }
  },
  data: () => ({
    dropdownOptions: {
      position: "bottom"
    },
    searchExpr: ["displayName"],
    dataSource: DropDownApis.searchRoles
  }),
  methods: {
    onValueChanged(e) {
      this.roles = e.value;
    },
    calculateDisplay(data) {
      return (data && data.displayName) || data.name;
    }
  },
  watch: {
    userTypeFilter: {
      immediate: false,
      handler(nv, ov) {
        if (nv != ov) {
          this.roles = [];
        }
        if (nv) {
          DropDownApis.searchRoles
            .load({ filter: ["userTypeId", "=", this.userTypeFilter] })
            .then(roles => {
              this.dataSource = new DataSource({
                store: roles,
                key: "id"
              });
            });
        } else {
          this.dataSource = DropDownApis.searchRoles;
        }
      }
    }
  },
  computed: {
    ...mapState(["currentUser", "currentLab"]),
    validatorMsgMap() {
      return { ...validatorMsgMapBase };
    },
    roles: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        return value;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.custom__tag {
  background-color: $neutral;
  color: #35495e;
}
</style>
